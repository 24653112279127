// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-en-tsx": () => import("./../src/pages/about.en.tsx" /* webpackChunkName: "component---src-pages-about-en-tsx" */),
  "component---src-pages-about-es-tsx": () => import("./../src/pages/about.es.tsx" /* webpackChunkName: "component---src-pages-about-es-tsx" */),
  "component---src-templates-author-tsx": () => import("./../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

